@use '../utils' as *;

/*----------------------------------------*/
/*  15. error css start
/*----------------------------------------*/
.it-error{
    &-title {
        font-size: 45px;
        font-weight: 700;
        text-transform: uppercase;
        padding-bottom: 10px;
        @media #{$xl,$lg}{
            font-size: 36px;
        }
        @media #{$md}{
            font-size: 34px;
        }
        @media #{$xs}{
            font-size: 31px;
        }
    }
    &-content {
        & p {
            font-size: 16px;
            margin-right: 80px;
            @media #{$xl,$lg}{
                font-size: 14px;
                margin-right: 0;
            } 
            @media #{$md}{
                padding-right: 150px;
                font-size: 14px;
                margin-right: 0;
            }
            @media #{$xs}{
                font-size: 14px;
                margin-right: 0;
            }
        }  
    }
    &-thumb {
        @media #{$md,$xs}{
            margin-bottom: 50px;
        } 
    }
}