@use '../utils' as *;

/*----------------------------------------*/
/*  04. blog css start
/*----------------------------------------*/

.it-blog {
    &-item-box {
        padding: 30px;
        padding-bottom: 40px;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-size: cover;
        @media #{$xl,$lg,$md,$xs}{                 
            padding: 20px;
            padding-bottom: 40px;
        }
    }
    &-title {
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        line-height: 1.4;
        margin-bottom: 20px;
        @media #{$xl}{                 
            font-size: 17px;
        }
        @media #{$lg}{                 
            font-size: 15px;
        }
        @media #{$md}{                 
            font-size: 17px;
        }
        @media #{$xs}{                 
            font-size: 16px;
        }
        & a {
            transition: .3s;
            &:hover {
                color: var(--it-theme-1);
            }
        }
        &-box {
            @media #{$xs}{                 
                margin-bottom: 30px;
            }
        }
    }
    &-thumb {
        margin-bottom: 25px;
        border-radius: 10px;
        & a {
            width: 100%;
            display: inline-block;
            & img {
                width: 100%;
                border-radius: 10px;
                transition: .9s;
            }
        }
    }
    &-meta {
        & span {
            & i {
                display: inline-block;
                color: var(--it-theme-1);
                margin-right: 5px;
            }

            color:#4D5756;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            @media #{$lg,$md,$xs}{                 
                font-size: 13px;
            }
            &:not(:last-child) {
                margin-right: 22px;
                @media #{$lg,$md,$xs}{                 
                    margin-right: 15px;
                }
            }
        }
    }
    &-shape-1 {
        position: absolute;
        top: 140px;
        right: 40%;
        animation: rotate2 4s linear infinite;
    }
    &-item{
        &:hover{
            & .it-blog-thumb{
                & img{
                    transform: scale(1.1) rotate(-2deg);
                }
            }
        }
    }
}
.it-blog-style-2{
    & .it-blog-meta  {
        & span {
            & i {
                color: var(--it-common-yellow);
            }
        }
    }
    & .it-blog-title  {
        &:hover {
            color:  var(--it-theme-4);
        }
    }
}
.it-blog-style-3 {
    & .it-blog-meta {
        & span {
            & i {
                color: var(--it-theme-4);
            }
        }
    }
    & .it-blog-title {
        font-size: 26px;
        @media #{$xl} {
            font-size: 23px;
        }
        @media #{$lg} {
            font-size: 24px;
        }
        @media #{$md,$xs}{                 
            font-size: 20px;
       }
        & a {
            &:hover {
                color: var(--it-theme-4);
            }
        }
    }
    & .it-blog-thumb {
        padding-bottom: 0;
        border-radius: 5px 5px 0 0;
        & img {
            border-radius: 5px 5px 0 0;
        }
    }
    & .it-blog-content {
        padding: 25px 45px;
        padding-bottom: 45px;
        border-radius: 0 0 5px 5px;
         background-color: var(--it-common-white);
       @media #{$md,$xs}{                 
            padding: 25px 25px;
            padding-bottom: 45px;
       }
    }
    & .it-blog-item {
        box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.07);
    }
}
.it-blog-style-4{
    & .it-blog-meta span i {
        color: var(--it-common-purple-3);
    }
    & .it-blog-title a:hover {
        color: var(--it-common-purple-3);
    }
    & .it-blog-title {
        @media #{$xl}{
            font-size: 24px;
        }
        @media #{$md,$xs}{
            font-size: 20px;
        }
    }
    & .it-blog-content {
        @media #{$md}{
            padding: 30px;
            padding-bottom: 30px;
        }
        @media #{$xs}{
            padding: 20px;
            padding-bottom: 20px;
        }
    }
    &.inner-style {
        & .it-blog-meta span i {
            color: var(--it-common-orange);
        }
        & .it-blog-title a:hover {
            color: var(--it-common-purple-3);
        }
        & .it-blog-title {
            @media #{$xl}{
                font-size: 24px;
            }
            @media #{$md,$xs}{
                font-size: 20px;
            }
        }
        & .it-blog-content {
            @media #{$md}{
                padding: 30px;
                padding-bottom: 30px;
            }
            @media #{$xs}{
                padding: 20px;
                padding-bottom: 20px;
            }
        }  
    }
}
.it-blog-style-5{
    & .it-blog-title a:hover {
        color: var(--it-theme-4);
    }
}
.it-blog-style-6 {
    & .it-blog-meta {
        & span {
            & i {
                color: var(--it-theme-5);
            }
        }
    }
    & .it-blog-title {
        & a {
            &:hover {
                color: var(--it-theme-1);
            }
        }
    }
    & .it-btn {
        background: var(--it-theme-1);
        &:hover {
            background-color: var(--it-theme-5);
        }
    }

    & .it-blog-thumb {
        padding-bottom: 0;
        & img {
            border-radius: 5px 5px 0 0;
        }
    }
    & .it-blog-content {
        padding: 25px 45px;
        padding-bottom: 45px;
        border-radius: 0 0 5px 5px;
         background-color: var(--it-common-white);
       @media #{$md,$xs}{                 
            padding: 25px 25px;
            padding-bottom: 45px;
       }
    }
    & .it-blog-item {
        box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.07);
    }
}
.it-blog-color{
    & .it-blog-title a:hover {
        color: var(--it-theme-2);
    }
    & .it-blog-meta span i {
        color: var(--it-theme-3);
    }
}
.ed-blog-style-2 {
    & .it-blog-content {
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.07);
        background: #E8E8F4;
    }
    & .it-blog-meta {
        & span {
            & i {
                color: var(--it-common-orange);
            }
        }
    }
    & .it-blog-title {
        & a {
            &:hover {
                color: var(--it-common-orange);
            }
        }
    }
} 
.ed-blog-style-2 {
    & .ed-btn-blog.theme-bg-2{
        &:hover {
            background-color: var(--it-theme-2);
            & i {
                background-color: #FF7461;
            }
        }
    }
    & .ed-blog-shape-1 {
        position: absolute;
        top: 3%;
        left: 54%;
        animation: rotate2 4s linear infinite;
    }
}
.ed-blog-style-3 {
    & .it-blog-content {
        box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.07);
        background: #E8E8F4;
    }
    & .it-blog-meta {
        & span {
            & i {
                color: var(--it-theme-6);
            }
        }
    }
    & .it-blog-title {
        & a {
            &:hover {
                color: var(--it-theme-6);
            }
        }
    }
    & .ed-btn-blog.radius {
        &:hover {
            background-color: var(--it-theme-7);
        }
    }
    & .ed-blog-shape-2 {
        position: absolute;
        top: 1%;
        left: -1%;
        animation: itswing-2 3s forwards infinite alternate;
        transform-origin: bottom left;
    }
} 

/*--- details css start here ----*/
.postbox__details{
    &-wrapper{
        padding-right: 30px;
        @media #{$md,$xs}{
            padding-right: 0;
            margin-bottom: 70px;
        }
    }
    &-tag{
        @media #{$xl,$md,$xs}{
            & a{
                font-size: 13px;
            }
        }
    }
    &-checkmark{
        padding-bottom: 40px;
        & ul{
            & li{
                list-style-type: none;
                position: relative;
                padding-left: 35px;
                margin-bottom: 20px;
                font-weight: 500;
                font-size: 17px;
                line-height: 1.2;
                color: #202124;
                @media #{$xs}{
                    font-size: 14px;   
                }
                &:last-child{
                    margin-bottom: 0;
                }
                & i{
                    height: 20px;
                    width: 20px;
                    background-color: var(--it-common-black);
                    color: var(--it-common-white);
                    border-radius: 50%;
                    line-height: 20px;
                    text-align: center;
                    font-size: 10px;
                    transform: translateX(2px);
                    position: absolute;
                    top: -2px;
                    left: 0;
                }
            }
        }
    }
    &-title{
        font-weight: 700;
        font-size: 36px;
        line-height: 1.2; 
        color: var(--it-common-black);
        padding-bottom: 10px;
        &:hover {
            color: var(--it-theme-1);
        }
        @media #{$xxxl}{
            font-size: 33px;
        }
        @media #{$xxl}{
            font-size: 33px;
        }
        @media #{$xl}{
            font-size: 32px;
            & br {
                display: none;
            }
        }
        @media #{$lg}{
            font-size: 25px;
            & br {
                display: none;
            }
        }
        @media #{$md}{
            font-size: 30px;
            & br {
                display: none;
            }
        }
        @media #{$xs}{
            font-size: 20px;
            & br {
                display: none;
            }
        }
    }
    &-title-box{
        & span {
            font-weight: 700;
            font-size: 14px;
            color: #888888;
            display: inline-block;
        }
        & p{
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            margin-bottom: 0;
            padding-bottom: 10px;
            padding-right: 25px;
            @media #{$xl}{
                font-size: 15px;
                & br {
                    display: none;
                }
            }
            @media #{$lg,$md}{
                font-size: 15px;
                padding-right: 0;
            }
            @media #{$xs}{
                font-size: 13px;
                padding-right: 0;
                & br {
                    display: none;
                }
            }
            
        }
    }
    &-img-box{
        padding-bottom: 20px;
        @media #{$xs}{
            flex-wrap: wrap;
        }
        & span{
            & img{
                border-radius: 20px;
                margin-bottom: 20px;
                &.img-mr{
                    margin-right: 20px;                    
                }
            }
        }
    }
    &-img-caption{
        font-weight: 400;
        font-size: 13px;
        line-height: 10px;
        color: #87888A; 
        & i{
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 10px;
            color: #5F6168;
        }
    }
}
.postbox {
    &__comment-box{
        @media #{$xs}{
            flex-wrap: wrap;
        }
    }
    &__content-wrap{
        & .it-contact-wrap{
            padding: 0;
            @media #{$md,$xs}{
                margin-bottom: 60px;
            }
        }
        & .it-contact__textarea-box {
            & textarea {
                padding-top: 10px;
                height: 150px;
            }
        }
    }
    &__p-right{
     margin-right: 20px;
     @media #{$xs,$md,$lg} {
        margin-right: 0px;
    }
    }
    &__thumb {
        & img {
            border-radius: 5px;
        }
        & .play-btn {
            position: absolute;
            top: calc(50% - 20px);
            left: 50%;
            @include transform(translate(-50%, -50%));
            z-index: 1;
            height: 90px;
            width: 90px;
            background-color: var(--it-common-white);
            text-align: center;
            line-height: 90px;
            color: var(--it-common-black);
           border-radius: 50%;
           animation: pulse 2s infinite;
           @media #{$xs} {
            height: 50px;
            width: 50px;
            line-height: 50px;
            & i{
                padding-left: 4px;
            }
        }
            &:hover {
                background-color: var(--it-common-blue-4);
                color: var(--it-common-white);
            }
        }
    }
    &__audio {
        height: 100%;
        width: 100%;

        & iframe {
            width: 100%;
            height: 100%;
            border: none;
        }
    }
    &__item {

        &-single {
            &:hover {
                @include box-shadow(none);
            }
        }
    }
    &__content {

        & p {
            & img {
                margin-bottom: 30px;
                max-width: 100%;
                border-radius: 10px;
                @media #{$md,$xs} {
                    width: 100%;
                }
            }
        }

        &-single {
            padding-left: 0;
            padding-right: 0;
            border: none;
        }
    }
    &__title {
        color: var(--it-common-black);
        line-height: 1.3;
        font-weight: 600;
        font-size: 36px;
        color: #202124;
        @media #{$xl} {
            font-size: 30px;
        }

        @media #{$lg} {
            font-size: 30px;
            & br{
                display: none;
            }
        }

        @media #{$md} {
            font-size: 30px;
            & br{
                display: none;
            }
        }

        @media #{$sm} {
            font-size: 22px;
            & br{
                display: none;
            }
        }

        @media #{$xs} {
            font-size: 22px;
            & br{
                display: none;
            }
        }

        & a {
            &:hover {
                color: var(--it-theme-1);
            }
        }
    }
    &__meta {
        margin-bottom: 20px;
        & a {
            font-weight: 400;
            font-size: 17px;
            line-height: 12px;
            color: #888888;
            display: inline-block;
            &:hover {
                color: var(--it-theme-1);
            }
        }
    }
    &__meta-2 {
        padding-bottom: 13px;
        & a {
            font-weight: 400;
            font-size: 17px;
            line-height: 12px;
            color: #888888;
            display: inline-block;
            @media #{$md,$xs}{
                font-size: 13px;
            }
            &:hover {
                color: var(--it-theme-1);
            }
        }
    }
    &__text {
        & img {
            max-width: 100%;
        }
        & p {
            font-size: 17px;
            @media #{$xl} {
                font-size: 16px;
            }
            @media #{$lg,$md,$xs} {
                font-size: 14px;
            }
        }
        &-single {
            & p {
                margin-bottom: 15px;
            }
        }
    }
    &__slider {
        & button {
            position: absolute;
            left: 50px;
            top: 50%;
            @include transform(translateY(-50%));
            z-index: 1;
            font-size: 30px;
            color: var(--it-common-white);

            &.slick-next {
                left: auto;
                right: 50px;

                @media #{$xs} {
                    right: 10px;
                }
            }

            @media #{$xs} {
                left: 10px;
            }
        }
    }
    &__comment {
        & ul {
            & li {
                margin-bottom: 45px;
                padding-bottom: 30px;
                list-style: none;
                padding: 25px;
                padding-right: 40px;
                border: 1px solid var(--it-theme-1);  
                @media #{$xs}{
                   padding-right: 25px;
                }           
            }
        &-title {
            font-size: 20px;
            font-weight: 500;
            color: var(--it-common-black);
            margin-bottom: 40px;
            margin-right:20px;
        }
        }
        &-title {
            font-weight: 600;
            font-size: 36px;
            line-height: 18px;
            letter-spacing: -0.02em;
            color: var(--it-common-white);
            margin-bottom: 40px;
            @media #{$xl}{
                font-size: 22px;
            }
            @media #{$lg,$md,$xs}{
                font-size: 20px;
            }
        }

        &-avater {
            & img {
                width: 154px;
                height: 154px;
                @include border-radius(50%);
                @media #{$xs}{
                    width: 65px;
                    height: 65px;
                }
            }
        }

        &-name {
            margin-bottom: 5px;
            & h5 {
                font-weight: 700;
                font-size: 22px;
                line-height: 20px; 
                color: var(--it-common-black);
                margin-bottom: 20px;
                @media #{$xl}{
                    font-size: 18px;
                }
                @media #{$lg,$md,$xs}{
                    font-size: 17px;
                }
            }


        }
        &-text{
            @media #{$xs} {
                margin-left: 0;
                margin-top: 15px;
            }
            & p {
                font-size: 15px;
                @media #{$xl,$lg,$md}{
                    font-size: 15px;
                    & br {
                        display: none;
                    }
                }
                @media #{$xs}{
                    font-size: 14px;
                    & br {
                        display: none;
                    }
                }
            }
        }
        &-reply {
            margin-top: 10px;
            padding-bottom: 17px;
            & span {
                margin-right: 40px;
                display: inline-block;
                @media #{$xs}{
                    font-size: 17px;
                }
            }
            & a {
                display: inline-block;
                font-weight: 500;
                font-size: 17px;
                color: var(--it-common-black);
                transition: .3s;
                @media #{$xs}{
                    font-size: 14px;
                }
                &:hover {
                    color: var(--it-theme-1);
                }
            }
        }

        &-agree {
            margin-bottom: 30px;
            & .form-check-label {
                font-weight: 500;
                font-size: 14px;
                line-height: 1.3;
                color: #87888A;
            }
            & .form-check-input {
                &:checked {
                    background-color: var(--it-common-blue-4);
                    border-color: transparent;
                }
                &:focus {
                    box-shadow:none;
                }
                &.form-check-input[type="checkbox"] {
                    border-radius: 2px;
                    margin-top: 4px;
                }
            }
        }
        &-input {
            position: relative;
            margin-bottom: 20px;

            & span {
                font-weight: 600;
                color: var(--it-common-black);
                margin-bottom: 12px;
                display: block;
            }

            & input,
            & textarea {
                height: 55px;
                padding: 0 20px;
                width: 100%;
                font-size: 14px;
                outline: none;
                border: 1px solid #f7f7f7;
                box-shadow: 0px 16px 24px rgba(189, 196, 205, 0.13);
                @include border-radius(7px);
                color: var(--it-common-black);
                &::placeholder{
                    color: rgba(116, 116, 116, .50);
                }
                &:focus{
                    border-color:var(--it-common-pink)  ;
                    color: var(--it-common-black);
                }
            }

            & textarea {
                height: 175px;
                resize: none;
                padding-top: 20px;
                padding-bottom: 20px;
            }
        }
    }
    &__tag {

        & span {
            font-size: 20px;
            margin-bottom: 17px;
            color: var(--it-common-black);
            margin-right: 30px;
            font-weight: 500;
        }
    }
    &__social {
        @media #{$lg,$md,$xs} {
            margin-top: 40px;
        }

        & span {
            font-size: 20px;
            color: var(--it-common-black);
            font-weight: 500;
        }

        & a {
            font-size: 15px;
            padding-left: 30px;

            & .it-linkedin {
                color: #0E6BA1;
            }

            & .it-pinterest {
                color: #D70220;
            }

            & .it-facebook {
                color: #0E6BA1;
            }

            & .it-twitter {
                color: #36B6ED;
            }
        }
    }
    &__navigation{
        &-more{
            @media #{$xs}{
                flex-wrap: wrap;
            }
        }
        &-img{
            margin-right: 15px;
            & img{
                border-radius: 12px;
            }
        }
        &-left{
            @media #{$xs}{
                margin-bottom: 30px;
            } 
        }
        &-content{
            & a{
                &:hover{
                    & span{
                        color: var(--it-common-blue-4);
                    }
                }
            }
            & span{
                font-weight: 500;
                font-size: 12px;
                line-height: 12px;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                color: #5F6168;
                padding-bottom: 15px;
                display: inline-block;
                transition: .3s;
                & i{
                    margin-right: 10px;
                }
            }
            & h5{
                font-weight: 700;
                font-size: 18px;
                line-height: 16px;
                color: #202124; 
                transition: .3s;
                @media #{$xs}{
                    font-size: 16px;
                }
                &:hover{
                    color: var(--it-common-blue-4);
                }
                @media #{$lg}{
                    font-size: 15px;
                }
            }
        }
    }
    &__navigation-right{
        & .postbox__navigation-img{
            margin-right: 0;
            margin-left: 15px;
        }
        & .postbox__navigation-content{
            & span{
                & i{
                    margin-right: 0;
                    margin-left: 10px;
                }
            }
        }
    }
    &__item {
        padding-top: 10px;
        padding-bottom: 50px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        & i {
            font-size: 48px;
            color: var(--it-theme-1);
            margin-bottom: 20px;
        }
        & p {
            font-size: 20px;
            line-height: 35px;
            font-weight: 700;
            color: var(--it-common-black);
            @media #{$lg,$md,$xs} {
                font-size: 17px;
            }
        }
    }
    &__details-tag {
        @media #{$xs}{
            margin-bottom: 20px;
        }
        & span {
            color: var(--it-common-black);
            font-weight: 700;
            display: inline-block;
            @media #{$lg,$md,$xs}{
                font-size: 17px;
            }
            padding-right: 5px;
        }
        & a {
            padding: 0 15px;
            height: 30px;
            line-height: 30px;
            display:inline-block ;
            background-color: #F2F2F2;
            color: var(--it-common-black);
            transition:.3s;
            margin-left: 10px;
            @media #{$lg}{
                padding: 0 7px;
                font-size: 12px;
            }
            @media #{$md}{
                padding: 0 10px;
                font-size: 14px;
            }
            @media #{$xs}{
                padding: 0 10px;
                font-size: 14px;
                margin-bottom: 10px;
            }
            &:hover {
                background-color: var(--it-theme-1);
                color: var(--it-common-white);
            }
        }
    }
    &__details-share {
        & span {
            color: var(--it-common-black);
            font-weight: 700;
            display: inline-block;
            padding-right: 15px;
        }
        & a {
            font-size: 16px;
            color: var(--it-common-black);
            display: inline-block;
            padding: 0 5px;
            transition: .3s;
            background-color: #F2F2F2;
            height: 35px;
            line-height: 35px;
            text-align: center;
            width: 35px;
            &:not(:last-child){
                margin-right: 8px;
            }
            @media #{$lg,$md,$xs}{
                font-size: 14px;
            }
            &:hover {
                color: var(--it-common-white);
                background-color: var(--it-theme-1);
            }
        }
    }
    &__details-share-wrapper {
        padding-top: 20px;
        padding-bottom: 65px;
    }
    &__comment-info {
        flex: 0 0 auto;
    }
    &__meta {
        & span {
            display: inline-block;
            padding-bottom: 5px;
            position: relative;
            padding-left: 25px;
            font-size: 16px;
            @media #{$md}{
                font-size: 14px;
            }
            @media #{$xs}{
                font-size: 13px;
            }
            &:not(:last-child){
                margin-right: 28px;
            }
            & i {
                position: absolute;
                top: 8px;
                left: 0;
                display: inline-block;
                font-size: 16px;
                font-weight: 400;
                color: var(--it-common-orange);
            }
        }
    }
    &__main-thumb {
        & img {
            width: 100%;
            border-radius: 5px;

        }
    }
    &__content-img {
        & img {
            width: 100%;
            @media #{$xs}{
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
        @media #{$xs}{
            flex-wrap: wrap;
        }
    }
}
.postbox__slider-arrow-wrap {
    @media #{$xs}{
        display: none;
    }
    & .postbox-arrow-prev {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0px;
        z-index: 9;
    }
    & .postbox-arrow-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0px;
        z-index: 9;
    }
    & button {
        height: 60px;
        width: 60px;
        line-height: 64px;
        background-color: var(--it-common-white);
        color: var(--it-common-black);
        text-align: center;
        font-size: 18px;
    }
}
.rc {
    &__post {
        transition: .3s;
        padding-top: 30px;
        border-top: 1px solid #E2E1E1;
        line-height: normal;
        &:last-child{
            padding-bottom: 30px;
            border-bottom: 1px solid #E2E1E1;
        }
        & ul {
            & li {
                &:not(:last-child) {
                    margin-bottom: 15px;
                }
            }
        }

        &-thumb {
            flex: 0 0 auto;
            object-fit: cover;
        }

        &-title {
            font-weight: 600;
            font-size: 15px;
            margin-right: 30px;
            color: var(--it-common-black);
            line-height: 23px;
            margin-bottom: 0;
            @media #{$lg,$md,$xs}{
                font-size: 14px;
                margin-right: 0;
            }
            & a {
                &:hover {
                    color: var(--it-theme-1);
                }
            }
        }
    }
    &__meta {
        line-height: 0;
        & span {
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 500;
            line-height: normal;
            display: inline-block;
            margin-bottom: 5px;
            & i {
                color: var(--it-common-orange);
                display: inline-block;
                margin-right: 5px;
            }
        }
    }
}
.sidebar {
    &__wrapper {
        padding-left: 40px;

        @media #{$lg,$md,$xs} {
            padding-left: 0;
        }
    }
    &__widget-content{
        & ul {
            & > li {
                padding: 0 30px;
                height: 50px;
                line-height: 50px;
                list-style-type: none;
                position: relative;
                margin-bottom: 10px;
                transition: .3s;
                border: 1px solid #E2E1E1;
                &:hover {
                    background-color: var(--it-theme-1);
                    & a {
                        color: var(--it-common-white);
                    }
                    &::after{
                        color: var(--it-common-white);
                    }
                }
                &::after{
                    content: '\f105';
                    right: 30px;
                    top: 50%;
                    position: absolute;
                    transform: translateY(-50%);
                    font-family: "Font Awesome 6 Pro";
                    color: var(--it-common-black);
                    font-size: 18px;
                }
                & > a {
                    font-weight: 500;
                    font-size: 16px;
                    color: var(--it-common-black);
                    transition: .3s;
                }
            }
        }
    }
    &__widget {       
        &-title {
            font-weight: 700;
            font-size: 24px;
            line-height:16px;    
            margin-bottom: 30px;
            text-transform: uppercase;
            color: var(--it-common-black);
            display: inline-block;
            @media #{$lg,$md,$xs}{
                font-size: 20px;
                & br {
                    display: none;
                }
            }
        }
        &.color-box {
            padding: 40px 35px;
            background-color: var(--it-common-black);
            @media #{$lg,$xs}{
                padding: 40px 20px;
            }
        }

        &:last-child {
            &-title {
                border-bottom: 0;
            }
        }
        & > ul {
            & > li {
                list-style: none;
                margin-bottom: 15px;
                &:last-child{
                    margin-bottom: 0;
                }
                & a {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 12px;
                    color: #5F6168;
                    display: flex;
                    justify-content: space-between;
                    &:hover {
                        color: var(--it-theme-1);
                    }
                    & i{
                        font-size: 18px;
                        transform: translateY(2px);
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    &__search {
        position: relative;
        & input {
            width: 100%;
            height: 60px;
            line-height: 60px;
            padding-left: 30px;
            text-transform: capitalize;
            background-color: #353535;
            border: none;
            padding-right: 60px;
            color: var(--it-common-white);
            outline: none;
            @media #{$md} {
                font-size: 15px;
            }
            @media #{$xs} {
                padding-right: 40px;
                padding-left: 15px;
                font-size: 14px;
            }
            &:focus{
                border-color: var(--it-theme-1);
            }
            &::placeholder {
                color: #777777;
            }
        }

        & button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 30px;
            border-radius: 50%;
            display: inline-block;
            color: var(--it-common-white);
            font-size: 18px;
        }

    }
    &__banner {
        &-content {
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 1;
            background: var(--it-theme-1);

            & h4 {
                padding: 15px 20px;
                font-size: 24px;
                color: var(--it-common-white);
                text-transform: uppercase;
                margin-bottom: 0;
            }
        }
    }
}
.tagcloud {
    &.space {
        & a {
            padding: 0px 15px;
        }
    }
    & a {
        color: #4D5756;
        background-color: #F2F2F2;
        display: inline-block;
        line-height: 1;
        padding: 0px 50px;
        height: 48px;
        line-height: 48px;
        margin-bottom: 15px;
        margin-right: 10px;
        transition: .3s;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        text-transform: capitalize;
        @media #{$xl}{
            font-size: 13px;
            padding: 0px 36px;
        }
        @media #{$lg}{
            font-size: 13px;
            padding: 0px 36px;
        }
        @media #{$md}{
            font-size: 13px;
            font-size: 13px;
            padding: 0px 36px;
        }
        @media #{$xs}{
            font-size: 13px;
            font-size: 13px;
            padding: 0px 36px;
        }
        &:hover {
            background: var(--it-common-purple-3);
            color: var(--it-common-white);
        }
    }
    & span{
        font-weight: 600;
        font-size: 16px;
        line-height: 12px;
        color: #202124;
        margin-right: 8px;
    }
}
.it-pagination ul li {
    padding-top: 30px;
    display: inline-block;
    &:not(:last-child) {
      margin-right: 10px;
    }
    a, span {
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        transition: 0.3s;
        background: var(--it-theme-1);
        font-family: var(--it-ff-heading);
        color: var(--it-common-white);
        border-radius:4px;
        box-shadow: 2px 3.464px 20px 0px rgba(4, 23, 26, 0.08);
        &.current{
            & i {
                display: inline-block;
                transform: translateY(2px);
            }
        }
    }
    a {
      &:hover, &.current {
        background-color: var(--it-theme-5);
      }
    }
    span {
      &:hover, &.current {
        background-color: var(--it-theme-5);
      }
    }
    & a{
        display: inline-block;
        &.color {
            background-color: var(--it-theme-5);
            color: var(--it-common-white);
            font-size: 18px;
        }
    }
}
 

