@use '../utils' as *;

/*----------------------------------------*/
/*  28. team css start
/*----------------------------------------*/
.it-team{
    &-item {
        &:hover {
            & .it-team-thumb {
                &::after{
                    opacity: 1;
                    visibility: visible;
                }
                & img {
                    transform: scale(1.2);
                }
            }
        }
    }
    &-left {
        @media #{$md,$xs}{                 
            margin-bottom: 50px;
        }
    }
    &-text {
        & p {
            padding-right: 60px;
            @media #{$lg}{                 
                padding-right: 48px;
                font-size: 14px;
            }
            @media #{$md,$xs}{                 
                padding-right: 0;
                font-size: 14px;
            }
        }
    }
    &-button {
        & a {
            @media #{$lg,$md,$xs}{                 
               margin-bottom: 20px;
            }
        }
    }
    &-thumb {
        position: relative;
        &::after{
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: rgba(14, 42, 70, 0.5);
            opacity: 0;
            visibility: hidden;
            transition: .3s;
        }
        & img {
            transition: .9s;
            width: 100%;
        }
    }
    &-thumb-box {
        border-radius: 5px;
        overflow: hidden;
        border: 6px solid var(--it-theme-1);
    }
    &-author {
        &-box {
            position: absolute;
            bottom: 20px;
            left: 30px;
            right: 28px;
            border-radius: 5px;
            padding: 15px 24px;
            padding-bottom: 7px;
            background-color: var(--it-common-white);
            @media #{$lg}{                 
                bottom: 15px;
                left: 10px;
                right: 10px;
            }
        }
        &-name {
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 0;
            line-height: 1;
            text-transform: capitalize;
            @media #{$xl}{                 
                font-size: 17px;
            }
            @media #{$lg}{                 
                font-size: 15px;
            }
            @media #{$md,$xs}{                 
                font-size: 17px;
            }
        }
        &-info {
            line-height: 0;
            & span {
                color:var(--it-theme-1);
                font-size: 13px;
                font-weight: 500;
                line-height: 32px; 
                text-transform: capitalize;
            }
        }
    }
    &-link {
        & a {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            display: inline-block;
            background-color: #E9E2FF;
            & svg {
                display: inline-block;
                color: var(--it-theme-1);
                transform: translate(-5px, -2px);
            }
        }
    }
    &-social-box{
        position: absolute;
        top: 35px;
        right: 30px;
        @media #{$xl,$lg,$xs}{                 
            top: 20px;
        }
        &:hover {
            & .it-team-social {
                opacity: 1;
                visibility: visible;
                left: 0;
            }
        }
        & button {
            height: 45px;
            width: 45px;
            left: 0;
            line-height: 48px;
            border-radius: 50%;
            padding: 0;
            background-color: var(--it-theme-1);
            color: var(--it-common-white);
        }
    }
    &-social {
        position: absolute;
        bottom: -155px;
        left: 50%;
        padding: 5px 13px;
        background-color: var(--it-common-white);
        opacity: 0;
        visibility: hidden;
        transition: .4s;
        z-index: 9;
        &::after{
            content: '';
            top: -9px;
            left: 50%;
            width: 12px;
            height: 12px;
            position: absolute;
            transform: translateX(-50%);
            background-color: var(--it-common-white);
            clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        }
        & a {
            font-size: 18px;
            color: var(--it-theme-1);
            &:not(:last-child){
                margin-bottom: 10px;
            }
        }
    }
    &-right-box {
        margin-left: -35px;
        @media #{$md,$xs}{                 
            margin-left: 0;
        }
    }
    &-shape {
        &-1 {
            position: absolute;
            left: 35%;
            top: 200px;
            animation: rotate2 4s linear infinite;
            @media #{$xl}{                 
                left: 30%;
            }
        }
        &-2 {
            position: absolute;
            top: 350px;
            left: 50px;
            animation: itleftright 1s infinite alternate;
            @media #{$xxl}{
                left: 0;
            }
            @media #{$xxl}{                 
                top: 10%;
            }
        }
        &-3 {
            position: absolute;
            bottom: 170px;
            left: 35%;
            animation: rotate2 4s linear infinite;
        }
    }
}
.it-team-3 {
    &-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 70.6%;
        z-index: -1;
        &.overley{
            &::after{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: rgba(14, 42, 70, 0.85);
            }
        }
    }
    &-item {
        &:hover {
            & .it-team-3-content {
                background-color: var(--it-theme-3);
                & span {
                    color: var(--it-common-white);
                }
            }
        }
    }
    &-content {
        border-radius: 0 0 5px 5px;
        padding: 30px 15px;
        padding-top: 0;
        transition: .3s;
        background-color: var(--it-common-black);
        & span {
            color: var(--it-theme-2);
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
        }
    }
    &-thumb {
        border-radius: 5px 5px 0 0 ;
        position: relative;
        &::after{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 0%;
            content: '';
            opacity: 0;
            visibility: hidden;
            transition: .4s ease-in-out;
            background: rgba(48, 55, 63, 0.70);
        }
        & img {
           border-radius: 5px 5px 0 0 ;
            width: 100%;
            transition: .9s;
       }
    }
    &-title {
        color: var(--it-common-white);
        font-family: var(--it-ff-poppins);
        font-size: 18px;
        font-weight: 700;
        line-height: 24px; 
        text-transform: uppercase;
        margin-bottom: 2px;
    }
    &-social{
        &-box{
            display: inline-block;
            transform: translateY(-22px);
            & button{
                height: 40px;
                width: 40px;
                line-height: 43px;
                border-radius: 50%;
                text-align: center;
                display: inline-block;
                color: var(--it-common-black);
                background-color: var(--it-theme-2);
                transition: .3s;
                padding: 0;
            }
            &:hover{
                & button{
                    background-color: var(--it-theme-2);
                    color: var(--it-common-white);
                }
                & .it-team-3-social-wrap{
                    bottom: 140%;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        &-wrap{
            background-color: var(--it-common-white);
            display: inline-block;
            padding: 11px 13px;
            position: absolute;
            bottom: 190%;
            left: 0;
            opacity: 0;
            visibility: hidden;
            transition: .3s;
            &::after{
                position: absolute;
                content: '';
                bottom: -5px;
                left: 50%;
                height: 10px;
                width: 10px;
                background-color: var(--it-common-white);
                transform: translateX(-50%) rotate(-45deg);
            }
            & > a{
                display: flex;
                display: table;
                color: var(--it-theme-2);
                line-height: 0;
                transition: .3s;
                &:hover{
                    color: var(--it-theme-3);
                }
                &:not(:last-child){
                    margin-bottom: 10px;
                }
            }
        }
    }
    &-item{
        border-radius: 5px;
        &:hover{
            & .it-team-3-thumb{
                & img{
                    transform: scale(1.1);
                }
                &::after{
                    opacity: 1;
                    visibility: visible;
                    height: 100%;
                }
            }
        }
    }
    &-shape {
        &-1 {
            position: absolute;
            left: 35px;
            top: 440px;
        }
        &-2 {
            position: absolute;
            top: 115px;
            left: 365px;
            animation: rotate2 12s linear infinite;
            @media #{$xxl}{
                left: 100px;
            }
            @media #{$xl}{
                top: 75px;
                left: 100px;
            }
        }
        &-3 {
            position: absolute;
            top: 150px;
            right: 320px;
            animation: itswing 1s forwards infinite alternate;
            @media #{$xl}{
                right: 100px;
            }
        }
    }
}
.it-team-3-style-2{
    & .it-team-3-item{
        &:hover{
            & .it-team-3-content {
                background-color: var(--it-theme-4);
            }
            & .it-team-3-social{
                &-box{
                    & button{
                        background-color: var(--it-common-yellow);
                        color: var(--it-common-white);
                    }
                }
                &-wrap{
                    & > a{
                        color: var(--it-common-yellow);
                        &:hover{
                            color: var(--it-theme-4);
                        }
                    }
                }
            }
        }
    }
    & .it-team-3-content {
        background-color: var(--it-common-yellow);
        transition: .3s;
    }
    & .it-team-3-author-box{
        & span {
            color: var(--it-common-white);
        }
    }
    & .it-team-3-social{
        &-box{
            & button{
                color: var(--it-common-white);
                background-color: var(--it-theme-4);
            }
    
        }
        &-wrap{
            & > a{
                color: var(--it-common-yellow);
                &:hover{
                    color: var(--it-theme-4);
                }
            }
        }
    }

}
.it-team-3-style-3{
    & .it-team-3-item {
        &:hover{
            & .it-team-3-content {
                background-color: var(--it-theme-1);
            }
            & .it-team-3-social{
                &-box{
                    & button{
                        background-color: var(--it-common-yellow);
                        color: var(--it-common-white);
                    }
                }
                &-wrap{
                    & > a{
                        color: var(--it-common-yellow);
                        &:hover{
                            color: var(--it-theme-1);
                        }
                    }
                }
            }
        }
    }
    & .it-team-3-social{
        &-box{
            & button{
                color: var(--it-common-white);
                background-color: var(--it-theme-1);
            }
    
        }
    }
}
.it-teacher-details{
    &-wrap{
        background-color: #E8E8F4;
        padding: 50px;
        @media #{$xl,$lg,$md,$xs}{
            font-size: 14px;
            padding: 50px 20px;
        }
    }
    &-left{
        &-thumb{
            & img{
                width: 100%;
                margin-bottom: 30px;
            }
        }
        &-social{
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px solid #D9D9D9;
            & a{
                height: 45px;
                width: 45px;
                border-radius: 50%;
                line-height: 45px;
                text-align: center;
                display: inline-block;
                background-color: var(--it-common-white);
                margin: 0px 6px;
                transition: .3s;
                &:hover {
                    color: var(--it-common-white);
                    background-color: var(--it-common-orange);
                }
            }
        }
        &-info{
            margin-bottom: 40px;
            & ul{
                & li{
                    list-style-type: none;
                    position: relative;
                    padding-left: 30px;
                    & a{
                        color:#4D5756;
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 1;
                        @media #{$xl,$lg,$md,$xs}{
                            font-size: 14px;
                        }
                    }
                    & i{
                        color: var(--it-common-orange);
                        position: absolute;
                        top: 8px;
                        left: 0;
                    }
                }
            }
        }
    }
    &-left {
        @media #{$md,$xs}{
            margin-bottom: 50px;
        }
    }
    &-right{
        background-color: var(--it-common-white);
        padding: 60px;
        margin-left: 20px;
        @media #{$md,$xs}{
            padding: 60px 15px;
            margin-left: 0;
        }
        & .it-progress-bar-wrap {
            & h4 {
                text-transform: uppercase;
                @media #{$lg,$md,$xs}{
                    font-size: 18px;
                }
            }
        }
        & .it-progress-bar-item {
            & label {
                @media #{$lg,$md,$xs}{
                    font-size: 14px;
                }
            }
        }
        &-title-box{
            margin-bottom: 40px;
            & h4{
                color:#0E2A46;
                font-size: 30px;
                font-weight: 700;
                text-transform: uppercase;
                @media #{$lg,$md}{
                    font-size: 24px;
                }
                @media #{$xs}{
                    font-size: 19px;
                }
            }
            & span{
                color:var(--it-common-orange);
                font-size: 13px;
                font-weight: 500;
                line-height: 1;
                text-transform: uppercase;
                margin-bottom: 30px;
                display: inline-block;
            }
            & p {
                @media #{$lg,$md,$xs}{
                    font-size: 14px;
                }
            }
        }
        &-content {
            & p {
                @media #{$lg,$md,$xs}{
                    font-size: 14px;
                }
            }
            & h4 {
                @media #{$xs}{
                    font-size: 20px;
                }
            }
        }
        & .it-progress-bar-wrap{
            & .it-progress-bar-item{
                padding: 5px 0;
            }
            & .it-progress-bar .progress-bar span {
                top: -22px;
            }
        }
    }
}
.ed-team {
    &-item{
        padding: 10px;
        border-radius: 5px;
        background-color: var(--it-common-white);
        &:hover{
            & .ed-team-thumb{
                & img{
                    transform: scale(1.1);
                }
            }
        }
        
    }
    &-content{
        padding-left: 20px;
        padding-top: 25px;
        padding-bottom: 20px;
    }
    &-thumb {
        border-radius: 5px 5px 0 0 ;
        & img {
           border-radius: 5px 5px 0 0 ;
            width: 100%;
            transition: .9s;
       }
    }
    &-title {
        color: var(--it-common-black);
        font-weight: 700;
        font-size: 21px;
        text-transform: capitalize;
        margin-bottom: 2px;
        & a {
            transition: .3s;
            &:hover {
                color: var(--it-theme-7);
            }
        }
    }
    &-author-box {
        & span {
            color: var(--it-theme-6);
        }
    }
    &-social{
        &-box{
            display: inline-block;
            position: absolute;
            top: -22px;
            right: 20px;
            & button{
                height: 40px;
                width: 40px;
                line-height: 43px;
                text-align: center;
                display: inline-block;
                color: var(--it-common-white);
                background-color: var(--it-theme-5);
                padding: 0;
            }
            &:hover{
                & .ed-team-social-wrap{
                    bottom: 101%;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        &-wrap{
            display: inline-block;
            position: absolute;
            bottom: 140%;
            left: 0px;
            opacity: 0;
            visibility: hidden;
            transition: .3s;
            & > a{
                height: 40px;
                width: 40px;
                line-height: 43px;
                text-align: center;
                display: inline-block;
                color: var(--it-common-white);
                background-color: var(--it-theme-5);
                margin-bottom: 2px;
                &:hover{
                    background-color: var(--it-theme-6);
                }
            }
        }
    }
    &-arrow-box{  
        @media #{$md,$xs}{
            display: none;
        }
        & button {            
            font-size: 24px;
            height: 62px;
            line-height: 58px;
            width: 62px;    
            border-radius: 50%;        
            transition: .3s;
            color: var(--it-theme-5);
            border: 1px solid var(--it-theme-5);
            &:hover {
                color: var(--it-common-white);
                background-color: var(--it-theme-5);
            }
            &.slider-prev {
                margin-right: 25px;
            }
        }
    }
    &-shape{
        &-1 {
            position: absolute;
            top: 4%;
            right: -1%;
            z-index: -1;
            animation: itswing-2 3s forwards infinite alternate;
            transform-origin: bottom right;
        }
    }
    &-area {
        &.inner-style{
            & .ed-team-social {
                &-box{
                    & button{
                        background-color: var(--it-common-orange);
                    }
                }
                &-wrap{
                    & > a{
                        background-color: var(--it-common-orange);
                        &:hover{
                            background-color: var(--it-theme-6);
                        }
                    }
                }
            }
            & .ed-team-author-box {
                & span {
                    color: var(--it-common-purple-3);
                }
            }  
            & .ed-team-arrow-box{  
                & button {         
                    border: 1px solid var(--it-common-orange);
                    color: var(--it-common-orange);
                    &:hover {
                        color: var(--it-common-white);
                        background-color: var(--it-common-orange);
                    }
                }
            } 
            & .ed-team-item {
                box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
            }
        }
    }
}