@font-face {
  font-family: 'flaticon_mycollection';
  src: url('../fonts/flaticon_mycollection.ttf?6e4f6370877f525a126d679715a7d338')
      format('truetype'),
    url('../fonts/flaticon_mycollection.woff?6e4f6370877f525a126d679715a7d338')
      format('woff'),
    url('../fonts/flaticon_mycollection.woff2?6e4f6370877f525a126d679715a7d338')
      format('woff2'),
    url('../fonts/flaticon_mycollection.eot?6e4f6370877f525a126d679715a7d338#iefix')
      format('embedded-opentype');
  //url("../fonts/flaticon_mycollection.svg?6e4f6370877f525a126d679715a7d338#flaticon_mycollection") format("svg");
}

i[class^='flaticon-']:before,
i[class*=' flaticon-']:before {
  font-family: flaticon_mycollection !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-plus:before {
  content: '\f101';
}
.flaticon-flexibility:before {
  content: '\f102';
}
.flaticon-funds:before {
  content: '\f103';
}
.flaticon-skill:before {
  content: '\f104';
}
.flaticon-star:before {
  content: '\f105';
}
.flaticon-exam:before {
  content: '\f106';
}
.flaticon-warehouse:before {
  content: '\f107';
}
.flaticon-higher-education:before {
  content: '\f108';
}
.flaticon-location:before {
  content: '\f109';
}
.flaticon-open:before {
  content: '\f10a';
}
.flaticon-next:before {
  content: '\f10b';
}
.flaticon-phone-call:before {
  content: '\f10c';
}
.flaticon-book:before {
  content: '\f10d';
}
.flaticon-email:before {
  content: '\f10e';
}
.flaticon-calendar:before {
  content: '\f10f';
}
.flaticon-check-mark:before {
  content: '\f110';
}
.flaticon-learning:before {
  content: '\f111';
}
.flaticon-shopping-cart:before {
  content: '\f112';
}
.flaticon-check:before {
  content: '\f113';
}
.flaticon-email-1:before {
  content: '\f114';
}
.flaticon-user:before {
  content: '\f115';
}
.flaticon-web-design:before {
  content: '\f116';
}
.flaticon-ux-design:before {
  content: '\f117';
}
.flaticon-coding:before {
  content: '\f118';
}
.flaticon-promotion:before {
  content: '\f119';
}
.flaticon-art:before {
  content: '\f11a';
}
.flaticon-mobile-development:before {
  content: '\f11b';
}
.flaticon-user-1:before {
  content: '\f11c';
}
.flaticon-bars:before {
  content: '\f11d';
}
.flaticon-teacher:before {
  content: '\f11e';
}
.flaticon-completed-task:before {
  content: '\f11f';
}
.flaticon-customer-review:before {
  content: '\f120';
}
.flaticon-class:before {
  content: '\f121';
}
.flaticon-share:before {
  content: '\f122';
}
.flaticon-facebook-app-symbol:before {
  content: '\f123';
}
.flaticon-instagram:before {
  content: '\f124';
}
.flaticon-pinterest:before {
  content: '\f125';
}
.flaticon-facebook:before {
  content: '\f126';
}
.flaticon-linkedin:before {
  content: '\f127';
}
.flaticon-coach:before {
  content: '\f128';
}
.flaticon-study:before {
  content: '\f129';
}
.flaticon-booking:before {
  content: '\f12a';
}
.flaticon-video:before {
  content: '\f12b';
}
.flaticon-clock:before {
  content: '\f12c';
}
.flaticon-pin:before {
  content: '\f12d';
}
.flaticon-search-interface-symbol:before {
  content: '\f12e';
}
.flaticon-video-1:before {
  content: '\f12f';
}
.flaticon-puzzle:before {
  content: '\f130';
}
.flaticon-certificate:before {
  content: '\f131';
}
.flaticon-bachelor:before {
  content: '\f132';
}
.flaticon-minimize-sign:before {
  content: '\f133';
}
