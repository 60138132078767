@use '../utils' as *;

/*----------------------------------------*/
/*  27. slider css start
/*----------------------------------------*/
.ed-slider{
    &-bg {
        background-size: cover;
        background-repeat: no-repeat;
    }
    &-content {
        padding: 197px 0;
        padding: 160px 0;
        @media #{$md,$xs}{
            padding: 120px 0;
        }
        & p {
            font-size: 15px;
            @media #{$lg,$xs}{
                & br {
                    display: none;
                }
            }
        }
    }
    &-subtitle {
        font-size: 17px;
        text-transform: uppercase;
        color: var(--it-theme-1);
        display: inline-block;
        @media #{$xl,$lg,$md,$xs}{
            font-size: 15px;
        }
    }
    &-title {
        font-weight: 700;
        font-size: 60px;
        text-transform: capitalize;
        color: var(--it-common-black-3);
        line-height: 1.2;
        font-family: var(--it-ff-heading);
        @media #{$xxl}{
            font-size: 54px;
        }
        @media #{$xl}{
            font-size: 48px;
        }
        @media #{$lg}{
            font-size: 48px;
            & br {
                display: none;
            }
        }
        @media #{$md}{
            font-size: 55px;
            & br {
                display: none;
            }
        }
        @media #{$xs}{
            font-size: 35px;
            & br {
                display: none;
            }
        }
        @media #{$sm}{
            font-size: 52px;
        }
        & i {
            font-style: normal;
            color: var(--it-common-yellow)
        }
    }
    &-thumb {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover
        }
        @media #{$xxxl}{
            width: 44%;
        }
        @media #{$xxl}{
            width: 48%;
        }
        @media #{$xl}{
            width: 48%;
        }
        @media #{$lg}{
            width: 50%;
        }
        @media #{$md,$xs}{
            position: static;
        }
    }
    &-instructor-box {
        position: absolute;
        bottom: 14%;
        right: 31%;
        z-index: 1;
        display: inline-block;
        border-radius: 10px;
        padding: 36px 40px;
        padding-right: 65px;
        background-color: var(--it-common-white);
        box-shadow: 0 0 20px 0 rgba(2, 52, 117, 0.15);
        animation: ittranslateX2 4s infinite alternate;
        @media #{$xl,$lg}{
            padding: 10px 10px;
        }
        & > div {
            @media #{$xl,$lg}{
                transform: scale(.7);
            }
        }
        & span {
            font-size: 30px;
            display: block;
            margin-bottom: 20px;
            color: var(--it-common-black-3);
            & i {
                font-weight: 500;
                font-style: normal;
                color: var(--it-theme-1);
            }
        }
    }
    &-shape {
        &-1 {
            position: absolute;
            top: 22%;
            left: -8%;
            z-index: -1;
            animation: zoom2 4s infinite;
        }
        &-2 {
            position: absolute;
            bottom: 16%;
            left: 29%;
            @media #{$xxxl}{
                left: 24%;
                transform: scale(.9);
            }
            @media #{$xxl}{
                left: 18%;
                transform: scale(.7);
            }
            @media #{$xl}{
                left: 18%;
                transform: scale(.6);
            }
        }
    }
}
.ed-slider-2{
    &-area {
        line-height: 0;
    }
    &-thumb {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%; 
        z-index: -2;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-position:center;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: opacity 1500ms ease-in, -webkit-transform 7000ms ease;
        transition: opacity 1500ms ease-in, -webkit-transform 7000ms ease;
        transition: transform 9000ms ease, opacity 1500ms ease-in;
        transition: transform 9000ms ease, opacity 1500ms ease-in, -webkit-transform 9000ms ease;
    }
    &-subtitle {
        & div {
            display: block;
            overflow: hidden;
            & span {
                font-weight: 400;
                font-size: 17px;
                display: block;
                margin-bottom: 10px;
                color: var(--it-theme-3);
                position: relative;
                display: block;
                line-height: 1;
                opacity: 0;
                transform: translateY(200px);
                -webkit-transform: translateY(200px);
                transition: 1s;
            }
        }
    }
    &-content {
        padding-top: 327px;
        padding-bottom: 235px;
        padding-left: 65px;
        position: relative;
        z-index: 4;
        @media #{$xxl}{
            padding-top: 270px;
            padding-bottom: 170px;
        }
        @media #{$xl}{
            padding-top: 270px;
            padding-bottom: 170px;
        }
        @media #{$lg}{
            padding-top: 250px;
            padding-bottom: 170px;
        }
        @media #{$md}{
            padding-top: 220px;
            padding-bottom: 170px;
            padding-left: 0;
        }
        @media #{$xs}{
            padding-top: 210px;
            padding-bottom: 140px;
            padding-left: 0;
        }
        & p {
            @media #{$md,$xs}{
                & br {
                    display: none;
                }
            }
        }
        & .ed-slider-title {
            margin-bottom: 10px;
            @media #{$xl}{
                font-size: 55px;
            }
            @media #{$sm}{
                font-size: 57px;
            }
            & i {
                font-style: normal;
                color: var(--it-theme-3);
            }
            & div {
                display: block;
                overflow: hidden;
                & span {
                    position: relative;
                    display: block;
                    opacity: 0;
                    transform: translateY(200px);
                    -webkit-transform: translateY(200px);
                    transition: 1s; 
                }
            }
        }
    }
    &-overley {
        &::after{
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            height: 100%;
            width: 100%; 
            background: linear-gradient(90deg, #000d3a 0%, rgba(0, 13, 58, 0.2) 100%);
        }
    }
    &-shape{
        &-1 {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
            @media #{$md,$xs}{
                display: none;
            }
        }
        &-2 {
            position: absolute;
            bottom: 6%;
            left: 12%;
            z-index: 2;
        }
    }    
    &-arrow-box{
        position: absolute;
        top: 50%;
        right: 16%;    
        display: flex;    
        flex-direction: column;
        z-index: 2;
        @media #{$lg}{
            right: 8%;
        }
        @media #{$md,$xs}{
            display: none;
        }
        & button {            
            font-size: 24px;
            height: 62px;
            line-height: 56px;
            width: 62px;    
            border-radius: 50%;        
            transition: .3s;
            color: var(--it-common-white);
            border: 2px solid var(--it-common-white);
            &.slider-prev{
                display: inline-block;
                transform: translateX(-50px);
            }
            &:hover {
                color: var(--it-common-white);
                border: 2px solid var(--it-theme-3);
                background-color: var(--it-theme-3);
            }
        }
    }
    &-box{
        & .swiper-slide{
            &.swiper-slide-active{
                & .ed-slider-2-thumb{
                    -webkit-transform: scale(1.35);
                    transform: scale(1.35)
                }   
                
            }
        }
    }
    &-text {
        & div {
            display: block;
            overflow: hidden;
            & p {
                position: relative;
                display: block;
                opacity: 0;
                transform: translateY(200px);
                -webkit-transform: translateY(200px);
                transition: 1s;
            }
        }
    }
    &-button {
        & div {
            display: inline-block;
            overflow: hidden;
            & a {
                position: relative;
                display: inline-block;
                opacity: 0;
                transform: translateY(200px);
                -webkit-transform: translateY(200px);
                transition: 1s;
            }
        }
    }
    &-box  {
        & .swiper-slide{
            &.swiper-slide-active{ 
                & .ed-slider-title  {
                    & div span {
                        opacity: 1;
                        transform: translateY(0px);
                        -webkit-transform: translateY(0px);
                    }
                }   
                & .ed-slider-2-subtitle  {
                    & div span {
                        opacity: 1;
                        transform: translateY(0px);
                        -webkit-transform: translateY(0px);
                    }
                }   
                & .ed-slider-2-text {
                    & div p {
                        opacity: 1;
                        transform: translateY(0px);
                        -webkit-transform: translateY(0px);
                    }
                }   
                & .ed-slider-2-button {
                    & div a {
                        opacity: 1;
                        transform: translateY(0px);
                        -webkit-transform: translateY(0px);
                    }
                }   
                
            }
        }
    }
}
.ed-slider-3 {
    &-bg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%; 
        z-index: -2;
        background-size: cover;
        background-repeat: no-repeat;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-position:center;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: opacity 1500ms ease-in, -webkit-transform 7000ms ease;
        transition: opacity 1500ms ease-in, -webkit-transform 7000ms ease;
        transition: transform 9000ms ease, opacity 1500ms ease-in;
        transition: transform 9000ms ease, opacity 1500ms ease-in, -webkit-transform 9000ms ease;
    }
    &-height {
        height: 800px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media #{$xl}{
            height: 720px;
        }
        @media #{$lg}{
            height: 650px;
        }
        @media #{$md}{
            height: 650px;
        }
        @media #{$xs}{
            height: 680px;
        }
        @media #{$sm}{
            height: 720px;
        }
        
        & .ed-slider-title {
            @media #{$xs}{
                & br {
                    display: none;
                }
            }
            & div {
                display: block;
                overflow: hidden;
                & span{
                    position: relative;
                    display: block;
                    opacity: 0;
                    transform: translateY(200px);
                    -webkit-transform: translateY(200px);
                    transition: 1s;
                    color: var(--it-common-white);
                  }
            }
        }
    }
    &-overley {
        position: relative;
        &::after{
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: -1;
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.3) 63.6%, rgba(18, 18, 18, 0) 100%);
        }
    }
    &-video {
        & span {
            height: 54px;
            width: 54px;
            line-height: 54px;
            background-color: #fff;
            border-radius: 50%;
            text-align: center;
            color: var(--it-theme-2);
            margin-right: 15px;
            position: relative;
            display: inline-block;
            & i {
                display: inline-block;
                padding-left: 3px;
            }
        }
        & a {
            color: var(--it-common-white);
            text-decoration: underline;
        }
    }
    &-text {
        margin-bottom: 30px;
        & div {
            display: block;
            overflow: hidden;
            & p {
                position: relative;
                display: block;
                opacity: 0;
                transform: translateY(200px);
                -webkit-transform: translateY(200px);
                transition: 1s;
                @media #{$xl,$lg,$md,$xs}{
                    font-size: 15px;
                    & br {
                        display: none;
                    }
                }
            }
        }
    }
    &-button-wrapper {
        line-height: 0;
        overflow: hidden;
    }
    &-button {
        opacity: 0;
        overflow: hidden;
        transform: translateY(200px);
        -webkit-transform: translateY(200px);
        transition: 1.5s;
        @media #{$xs}{
            flex-wrap: wrap;
        }
        @media #{$sm}{
            flex-wrap: nowrap;
        }
        & .ed-btn-square {
            @media #{$xs}{
                margin-bottom: 25px;
            }
            @media #{$sm}{
                margin-bottom: 0px;
            }
        }
    }
    &-wrapper{
        & .swiper-slide{
            &.swiper-slide-active{
                & .ed-slider-3-bg{
                    -webkit-transform: scale(1.35);
                    transform: scale(1.35)
                }  
                & .ed-slider-title {
                    & div span {
                        opacity: 1;
                        transform: translateY(0px);
                        -webkit-transform: translateY(0px);
                    }
                }   
                & .ed-slider-3-text {
                    & div p {
                        opacity: 1;
                        transform: translateY(0px);
                        -webkit-transform: translateY(0px);
                    }
                }   
                & .ed-slider-3-button {
                    opacity: 1;
                    transform: translateY(0px);
                    -webkit-transform: translateY(0px);
                }   
                
            }
        }
    }
}

.line_wrap {
    top: 0;
    left: -10%;
    bottom: 0px;
    width: 100%;
    z-index: 1;
    display: block;
    position: absolute;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
}
.line_wrap:before {
  width: 1px;
  left: -1px;
  content: "";
  height: 100px;
  position: absolute;
  animation: scroll1 15s ease-out infinite;
  background-image: linear-gradient(0deg, #000000, transparent);
}
.line_wrap .line_item {
    width: 20%;
    float: left;
    height: 100%;
    position: relative;
    display: inline-block;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    z-index: 9;
}
.line_wrap .line_item:before {
  width: 6px;
  right: -3px;
  content: "";
  height: 6px;
  border-radius: 50%;
  position: absolute;
  background-color: #1B5966;
}
.line_wrap .line_item:nth-child(odd):before {
  
  animation: scroll2 15s ease-out infinite;
  /*! background-image: linear-gradient(0deg, transparent, #000000); */
}
.line_wrap .line_item:nth-child(even):before {
  animation: scroll1 9s ease-out infinite;
  background-image: linear-gradient(0deg, #000000, transparent);
}
.line_wrap .line_item:last-child:before {
  animation: scroll1 12s ease-out infinite;
  background-image: linear-gradient(0deg, #000000, transparent);
}

.line_wrap.line_white {
  border-left: 1px solid rgba(255, 255, 255, 0.07);
}
.line_wrap.line_white:before {
  background-image: linear-gradient(0deg, #ffffff, transparent);
}
.line_wrap.line_white .line_item {
  border-right: 1px solid rgba(255, 255, 255, 0.07);
}
.line_wrap.line_white .line_item:nth-child(odd):before {
  background-image: linear-gradient(0deg, transparent, #ffffff);
}
.line_wrap.line_white .line_item:nth-child(even):before {
  background-image: linear-gradient(0deg, #ffffff, transparent);
}

.hover-anim {
	background-image: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff);
	background-size: 0% 1px, 0 1px;
	background-position: 100% 100%, 0 96%;
	background-repeat: no-repeat;
	transition: background-size 0.4s linear;
	display: inline;
    &:hover {
        background-size: 0 1px, 100% 1px;
    }
}
.hover-anim-2 {
	background-image: linear-gradient(#0E2A46, #0E2A46), linear-gradient(#0E2A46, #0E2A46);
	background-size: 0% 1px, 0 1px;
	background-position: 100% 100%, 0 96%;
	background-repeat: no-repeat;
	transition: background-size 0.4s linear;
	display: inline;
    &:hover {
        background-size: 0 1px, 100% 1px;
    }
}

.ed-slider-3-wrapper {
    & .ed-slider-2-arrow-box {
        & button {
            &:hover {
                background-color: var(--it-common-orange);
                border: 1px solid var(--it-common-orange);
            }
        }
    }
}