@use '../utils' as *;

/*----------------------------------------*/
/*  06. career css start
/*----------------------------------------*/ 
.it-career{
    &-bg{               
        background: linear-gradient(180deg, #F2F2F2 0%, rgba(242, 242, 242, 0.00) 100%);
    }
    &-item {
        border-radius: 5px;
        padding: 80px 30px;
        @media #{$xs}{          
            padding: 40px 20px;
        }
    }
    &-content {
        position: relative;
        z-index: 2;
        & > span {
            font-size: 20px;
            font-weight: 400;
            line-height: 32px; 
            text-transform: capitalize;
            color: var(--it-common-white);
            display: block;
            padding-bottom: 17px;
            @media #{$lg}{                 
                font-size: 16px;
            }
        }
        & p {
            color: #FFF;
            font-family: var(--it-ff-heading);
            font-size: 20px;
            font-weight: 700;
            line-height: 32px;
            text-transform: capitalize;
            margin-bottom: 25px;
            @media #{$lg}{                 
                font-size: 19px;
            }
            @media #{$xs}{                 
                & br {
                    display: none;
                }
            }
        }
    }
    &-thumb {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;        
        @media #{$lg}{                 
            bottom: -8%;
            right: -15%;
        }
        @media #{$xs}{                 
            display: none;
        }
    }
    &-shape {
        &-1 {
            position: absolute;
            top: 0;
            left: 0;
        }
        &-2 {
            position: absolute;
            top: 0;
            right: 0;
        }
        &-3 {
            position: absolute;
            top: 105px;
            right: 170px;
            animation: rotate2 4s linear infinite;
        }
        &-4 {
            position: absolute;
            left: 430px;
            top: 195px;
            animation: itleftright 1s infinite alternate;
            @media #{$xxl}{                 
                left: 350px;
            }
            @media #{$xl}{                 
               left: 320px;
            }  
            @media #{$lg}{                 
               left: 200px;
            } 
        }
        &-5 {
            position: absolute;
            left: 200px;
            bottom: 18px;
            animation: rotate2 4s linear infinite;
            @media #{$xxl}{                 
                bottom: -40px;
            }
            @media #{$xl}{                 
               bottom: -50px;
            }   
        }
        &-6 {
            position: absolute;
            top: 95px;
            left: 50px;
            animation: itupdown 0.8s infinite alternate;
        }
    }
}
.it-about-3-mv-item{
    & p{
        @media #{$xs}{
            font-size: 14px;
        }
    }
}
.it-career-style-3{
    &.it-career-bg {
        background: none;
    }
    & .it-career-item {
        background-color: var(--it-theme-2);
    }
    & .it-career-content > span {
        font-size: 45px;
        font-weight: 700;
        font-family: var(--it-ff-poppins);
        @media #{$xs}{
            font-size: 30px;
        }
    }
    & .it-career-content p {
        font-size: 15px;
        font-weight: 400;
        line-height: 32px;
        color: var(--it-common-white);
        max-width: 310px;
    }
}

.it-progress-bar{
    &-item{
        padding: 30px 0px;
        overflow: hidden;
        padding-bottom: 5px;
        @media #{$lg}{
            padding-top: 20px;
        }
        & label{
            font-size: 17px;
            font-weight: 600;
            line-height: 1;
            margin-bottom: 12px;
            display: inline-block;
            color: var(--it-common-black);
            @media #{$xs}{
                font-size: 14px;
            }
        }
    }
    & .progress{
        overflow: visible;
        border-radius: 10px;
        background: rgba(137, 186, 180, 0.3);
        height: 10px;
    }
    & .progress-bar{
        background-color: var(--it-theme-3);
        border-radius: 30px;
        position: relative;
        overflow: visible;
        height: 100%;
        border-radius: 10px;
        top: 0;
    }
    & .progress-bar span {
        position: absolute;
        right: 0;
        top: -30px;
        transform: translateY(-50%);
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        color: var(--it-common-white);
        background-color: var(--it-theme-3);
        padding: 5px 7px;
        &::after{
            position: absolute;
            bottom: -5px;
            left: 50%;
            height: 10px;
            width: 10px;
            background-color: var(--it-theme-3);
            content: '';
            transform: translateX(-50%) rotate(-45deg);
        }
    }
    &-wrap {
        &.inner-style {
            & .progress-bar{
                background-color: var(--it-common-purple-3);
            }
            & .progress-bar span {
                background-color: var(--it-common-purple-3);                
                &::after{
                    background-color: var(--it-common-purple-3);  
                }
            }
            & .progress-bar.orange{
                background-color: var(--it-common-orange);
                & span {
                    background-color: var(--it-common-orange);
                    &::after{
                        background-color: var(--it-common-orange);  
                    }
                }
            }
        }
    }
}

.it-value{
    &-img-shape{
        position: absolute;
        top: 50%;
        right: -40px;
        transform: translateY(-50%);
        z-index: -1;
    }
    &-img-shape-2{
        position: absolute;
        bottom: -8%;
        left: 4%;
        z-index: -1;
    }
    &-play-btn{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        height: 80px;
        width: 80px;
        line-height: 80px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        background-color: var(--it-common-white);
        color: var(--it-common-yellow);
    }
    &-right-img{
        & img{
            width: 100%;
        }
    }
    &-right-wrap{
        margin-left: 60px;
        @media #{$md,$xs}{
            margin-left: 0;
        }
    }
    &-shape{
        &-1{
            position: absolute;
            bottom: 18%;
            left: 2%;
            animation: itupdown 0.8s infinite alternate;
            @media #{$xxxl}{
                bottom: -8%;
                left: -1%;
            }
            @media #{$xxl}{
                bottom: -7%;
                left: -1%;
            }
        }
        &-2{
            position: absolute;
            top: -12%;
            right: -6%;
            z-index: -1;
        }
        &-3 {
            position: absolute;
            top: 12%;
            left: 34%;
        }
    }
}

.it-value-title-box{
    & p{
        @media #{$xl,$lg,$md,$xs}{
            & br{
                display: none;
            }
        }
    }
}

.it-progress-bar-wrap{
    @media #{$md,$xs}{
        margin-bottom: 60px;
    }
}
.ed-career-style-2 {
    & .it-career-content > span{
        font-size: 14px;
        text-transform: uppercase;
    }
    & .it-career-content > p{
        font-weight: 700;
        font-size: 26px;
        text-transform: capitalize;;
    }
}