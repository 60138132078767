@use '../utils' as *;


.it-breadcrumb-bg {
    height: 365px;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    @media #{$xl} {
        height: 320px;
    }

    @media #{$xs} {
        height: 300px;
    }
}

.it-breadcrumb {
    &-title {
        color: var(--it-common-black);
        font-size: 60px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 5px;

        @media #{$xxl} {
            font-size: 55px;
        }

        @media #{$xl} {
            font-size: 48px;
        }

        @media #{$lg} {
            font-size: 38px;
        }

        @media #{$md} {
            font-size: 43px;
        }

        @media #{$xs} {
            font-size: 30px;
        }

        @media #{$sm} {
            font-size: 30px;
        }
    }

    &-content {
        @media #{$xs} {
            margin-bottom: 30px;
        }

        @media #{$sm} {
            margin-bottom: 0px;
        }
    }

    &-list {
        display: inline-block;
        border-radius: 30px;

        & span {
            color: var(--it-common-black);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            text-transform: capitalize;

            @media #{$lg,$md,$xs} {
                font-size: 14px;
            }

            &.dvdr {
                color: var(--it-common-orange);
                font-weight: 500;
                font-size: 16px;
                padding: 0 3px;
            }
        }
    }

    &-shape {
        &-1 {
            position: absolute;
            top: 28%;
            left: 2%;
            animation: moving 11s linear infinite;

            @media #{$xl,$lg} {
                left: 7%;
            }

            @media #{$md} {
                top: 20%;
                left: 11%;
            }

            @media #{$xs} {
                display: none;
            }
        }

        &-2 {
            position: absolute;
            bottom: 40%;
            left: 22%;
            animation: ittranslateX2 3s infinite alternate;

            @media #{$md} {
                bottom: 24%;
                left: 9%;
            }

            @media #{$xs} {
                display: none;
            }
        }

        &-3 {
            position: absolute;
            bottom: 15%;
            right: 30%;
            animation: rotate2 8s linear infinite;

            @media #{$xs} {
                display: none;
            }
        }

        &-4 {
            position: absolute;
            top: 15%;
            right: 3%;
            animation: rotate2 4s linear infinite;

            @media #{$xs} {
                display: none;
            }
        }
    }
}