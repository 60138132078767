@use '../utils' as *;

/*----------------------------------------*/
/*  20. funfact css start
/*----------------------------------------*/

.it-funfact {
  &-bg-wrap {
    padding: 55px 70px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 200px;
    @media #{$xl,$lg,$md,$xs} {
      padding: 55px 25px;
    }
    @media #{$md,$xs} {
      border-radius: 0;
    }
  }
  &-item {
    @media #{$md,$xs} {
      margin-bottom: 30px;
    }
  }
  &-icon {
    & span {
      height: 78px;
      width: 78px;
      line-height: 89px;
      text-align: center;
      display: inline-block;
      margin-right: 20px;
      background-color: var(--it-common-white);
      color: var(--it-common-black);
      font-size: 38px;
      border-radius: 50%;
      @media #{$lg} {
        height: 65px;
        width: 65px;
        line-height: 78px;
        text-align: center;
        display: inline-block;
        margin-right: 15px;
      }
    }
  }
  &-content {
    line-height: 0;
    & h6 {
      font-size: 39px;
      font-weight: 700;
      line-height: 1;
      color: var(--it-common-black);
      text-transform: capitalize;
      margin-bottom: 15px;
      @media #{$xl} {
        font-size: 35px;
      }
      @media #{$lg} {
        font-size: 30px;
      }
      @media #{$md,$xs} {
        font-size: 35px;
      }
      @media #{$sm} {
        font-size: 29px;
        margin-bottom: 5px;
      }
      & i {
        font-style: normal;
      }
    }
    &-title {
      font-size: 17px;
      font-weight: 400;
      line-height: 1;
      text-transform: capitalize;
      color: var(--it-common-black);
      @media #{$xl} {
        font-size: 14px;
      }
      @media #{$lg} {
        font-size: 11px;
      }
      @media #{$md,$xs} {
        font-size: 14px;
      }
      @media #{$sm} {
        font-size: 12px;
      }
    }
  }
}
.it-funfact-4 {
  &-wrap {
    height: 250px;
    width: 250px;
    line-height: 250px;
    text-align: center;
    border: 1px solid var(--it-common-white);
    border-radius: 50%;
    @media #{$lg} {
      height: 220px;
      line-height: 220px;
    }
  }
  &-item {
    & h4 {
      font-size: 45px;
      font-weight: 600;
      text-transform: capitalize;
      color: var(--it-common-white);
      font-family: var(--it-ff-poppins);
    }
    & p {
      font-size: 16px;
      font-weight: 500;
      color: var(--it-theme-3);
      margin-bottom: 0;
    }
  }
  &-shape-1 {
    position: absolute;
    top: 0;
    right: 0;
  }
  &-shape-2 {
    position: absolute;
    bottom: 10%;
    left: 2%;
  }
  &-area {
    background-color: #7768e5;
  }
}
.it-funfact-5 {
  &-bg {
    background-repeat: no-repeat;
    background-size: cover;
  }
  &-item {
    padding: 55px 15px 45px 15px;
    border-radius: 5px;
    background-color: var(--it-common-white);
  }
  &-icon {
    height: 87px;
    width: 87px;
    border-radius: 50%;
    line-height: 102px;
    text-align: center;
    margin: 0 auto;
    background-color: var(--it-common-yellow-2);
    position: relative;
    & span {
      font-size: 45px;
      color: var(--it-common-white);
    }
    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      transform: scale(1.15);
      border: 1px dashed var(--it-common-yellow-2);
    }
  }
  &-number {
    font-size: 49px;
    font-weight: 700;
    margin-bottom: 0;
    text-transform: capitalize;
  }
  &-content {
    & span {
      font-size: 21px;
      font-weight: 500;
      text-transform: capitalize;
      color: #4d5756;
    }
  }
  &-shape {
    &-1 {
      position: absolute;
      bottom: 140px;
      left: 100px;
      animation: rotate2 12s linear infinite;
      @media #{$xxl} {
        bottom: 0;
        left: 0;
      }
    }
    &-2 {
      position: absolute;
      top: 95px;
      right: 140px;
      animation: rotate2 12s linear infinite;
    }
  }
}

.ed-funfact {
  &-wrap {
    & .it-funfact-item {
      &.border-style-1 {
        position: relative;
        &::after {
          position: absolute;
          content: '';
          top: 50%;
          right: -5%;
          width: 1px;
          height: 162px;
          transform: translateY(-50%);
          border: 1px dashed #0e2a46;
        }
      }
    }
    & .it-funfact-icon {
      & span {
        background-color: #fc9f0b;
        position: relative;
        margin: 10px;
        margin-bottom: 0;
        &::after {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: 50%;
          border: 1px dashed #0e2a46;
          transform: scale(1.25);
        }
      }
    }
    & .it-funfact-content {
      & h6 {
        margin-bottom: 10px;
      }
      &-title {
        font-size: 21px;
        font-weight: 500;
        color: var(--it-common-black);
      }
    }
  }
  &-shape-1 {
    position: absolute;
    top: 20%;
    right: 10%;
    top: -19%;
    right: 7%;
    z-index: -1;
    animation: rotate2 4s linear infinite;
  }
}
