@use '../utils' as *;

/*----------------------------------------*/
/*  03. about css start
/*----------------------------------------*/
.it-about{
    &-right-box {
        margin-left: -35px;
        @media #{$lg,$md,$xs}{                 
            margin-left: 0;
        }
    }
    &-main-thumb {
        @media #{$md,$xs}{                 
            margin-bottom: 50px;
        }
        & img {
            border-radius: 5px;
            @media #{$lg}{                 
                max-width: 100%;
            }
            @media #{$xs}{                 
                width: 100%;
            }
        }
    }
    &-thumb {
        &-box {
            margin-right: 80px;
            @media #{$lg,$md,$xs}{                 
                margin-right: 0;
            }
        }
        &-1 {
            position: absolute;
            top: -55px;
            left: 0;
            & img {
                border-radius: 5px;
            }
        }
        &-2 {
            position: absolute;
            bottom:-65px;
            left: 0;
            & img {
                border-radius: 5px;
            }
        }
    }
    &-content {
        @media #{$xs}{   
            margin-bottom: 15px;
        }
        & h5 {
            font-size: 17px;
            text-transform: uppercase;

        }
        & p {
            font-size: 17px;
            line-height: 32px;
            color: var(--it-common-black);
            @media #{$xl}{                 
                font-size: 14px;
                line-height: 26px;
            }
            @media #{$lg}{                 
                font-size: 12px;
                line-height: 26px;
            }
            @media #{$md,$xs}{                 
                font-size: 14px;
                line-height: 26px;
            }
        }
        &-wrapper{
            @media #{$xs}{   
                flex-wrap: wrap;
            }
        }
    }
    &-text {
        & p {
            @media #{$xl,$lg,$md,$xs}{                 
                font-size: 14px;
            }
        }
    }
    &-shape {
        &-1 {
            position: absolute;
            top: -115px;
            left: -105px;
            z-index: -1;
            animation: itupdown 0.8s infinite alternate;
            @media #{$lg}{   
                top: -78px;
                left: 20px;
            }
            @media #{$md}{   
                top: -52px;
                left: 95px;
            }
        }
        &-2 {
            position: absolute;
            right: 75px;
            top: -50px;
         
        }
        &-3 {
            position: absolute;
            bottom: -65px;
            right: 25px;
            @media #{$md}{                 
                bottom: -22px;
                right: -17px;
            }
        }
        &-4 {
            position: absolute;
            top: 165px;
            right: 120px;
            animation: rotate2 4s linear infinite;
            @media #{$md}{                 
                top: 241px;
                right: 114px;
            }
        }
    }
}
.it-about-2{
    &-thumb-wrap {
        margin-right: 10px;
    }
    &-right {
        margin-left: 30px;
        @media #{$xl,$lg,$md,$xs}{                 
            margin-left: 0;
        }
    }
    &-bottom {
        margin-right: 50px;
        @media #{$xl,$lg,$md}{                 
            margin-right: 0;
        }
        @media #{$xs}{                 
            margin-right: 0;
            flex-wrap: wrap;
        }
    }
    &-thumb-wrap {
        @media #{$md,$xs}{                 
            margin-top: 50px;
        }
    }
    &-text-2 {
        & span {
            color: var(--it-common-black);
            font-family: var(--it-ff-heading);
            font-size: 22px;
            font-weight: 600;
            line-height: 1.3;
            display: block;
            padding-left: 12px;
            @media #{$xl}{                 
                font-size: 19px;
            }
            @media #{$lg}{                 
                font-size: 16px;
            }
            @media #{$md}{                 
                font-size: 19px;
            }
            @media #{$xs}{                 
                font-size: 15px;
            }
        }
        position: relative;
        &::before{
            content: '';
            position: absolute;
            top: -2px;
            left: 0;
            height: 100%;
            width: 2px;
            background-color: var(--it-common-black);
        }
    }
    &-text {
        & p {
            @media #{$xl,$lg,$md,$xs}{                 
                font-size: 14px;
                & br {
                    display: none;
                }
            }
        }
    }
    &-thumb {
        & img {
            @media #{$xl,$lg}{                 
                transform: scale(.9);
            }
        }
    }
    &-icon {
        & img {
            margin-right: 20px;
        }
        & span {
            color: var(--it-common-black);
            font-family: var(--it-ff-heading);
            font-size: 18px;
            font-weight: 600;
            line-height: 29px;
            @media #{$lg,$md,$xs}{                 
               font-size: 16px;
            }
        }
    }
    &-video {
        @media #{$xs}{                 
            margin-top: 30px;
        }
    }
    &-video-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        & a {
            height: 52px;
            width: 52px;
            border-radius: 50%;
            line-height: 52px;
            display: inline-block;
            text-align: center;
            color:var(--it-common-black);
            background-color: var(--it-common-white);
            & i {
                display: inline-block;
                margin-left: 3px;
            }
            &::after{
                position: absolute;
                content: "";
                left: 50%;
                top: 50%;
                width: 80%;
                height: 80%;
                background-color: rgba(255, 255, 255, 0.774);
                animation: borderanimate2 2.5s linear infinite;
                z-index: -1;
                border-radius: 50%;
            }
            &::before{
                position: absolute;
                content: "";
                left: 50%;
                top: 50%;
                width: 80%;
                height: 80%;
                transform: scale(1.5);
                border: 1px solid rgba(255, 255, 255, 0.774);
                animation: borderanimate2 2s linear infinite;
                z-index: -1;
                border-radius: 50%;
            }
        }
    }
    &-thumb-sm {
        position: relative;
        border-radius: 5px;
        &::after{
            position: absolute;
            content: "";
            left: 0%;
            top: 0%;
            width: 100%;
            height: 100%;
            background-color:rgba(1, 14, 33, 0.6);
            border-radius: 5px;
        }
        & img {
            border-radius: 5px;
        }
    }
    &-big-text {
        position: absolute;
        left: -9%;
        bottom: 40%;
        transform: rotate(-90deg);
        & h6 {
            -webkit-text-stroke: 1px #22A3D1;
            font-weight: 600;
            color: transparent;
            font-size: 150px;
            font-weight: 700;
            text-transform: capitalize;
            font-family: var(--it-ff-poppins);
            @media #{$xxxl}{
                font-size: 110px;
            }
            @media #{$xxl,$xl,$lg,$md,$xs}{
                display: none;
            }
        }
    }
    &-shape {
        &-1 {
            position: absolute;
            left: -23%;
            top: -17%;
            animation: itswing 1s forwards infinite alternate;
            transform-origin: bottom left;
        }
        &-2 {
            position: absolute;
            top: 100px;
            right: 35px;
            animation: itupdown 0.8s infinite alternate;
            @media #{$xxxl,$xxl}{
                top: 10px;
                right: 0;
                transform: scale(.5);
            }
        }
    }
}
.it-about-3{
    &-left{
        &-box{
            padding-right: 75px;
            @media #{$xs}{
                padding-right: 0;
            }
        }
        &-shape{
            &-1{
                position: absolute;
                top: 4%;
                right: 31%;
                animation: itrotate 3s ease-in-out 3s infinite alternate forwards;
            }
            &-2{
                position: absolute;
                bottom: 2%;
                left: -14%;
                z-index: -1;
                animation: rotate2 15s linear infinite;
            }
            &-3{
                position: absolute;
                bottom: 0%;
                right: 18%;
                @media #{$xl,$lg}{
                    right: 0;
                }
            }
            &-4{
                position: absolute;
                top: 2%;
                right: 2%;
                animation: itswing 1s forwards infinite alternate;
                transform-origin: bottom right;
                @media #{$xl}{
                    top: -4%;
                }
                @media #{$lg}{
                    top: -6%;
                    right: 0;
                }
            }
            &-5{
                position: absolute;
                bottom: 13%;
                right: 0%;
                z-index: -1;
                @media #{$xxl,$xl,$lg,$md,$xs}{
                    display: none;
                }
            }
        }
    }
    &-title-box{
        & p{
            color:#4D5756;
            font-size: 17px;
            font-weight: 400;
            line-height: 32px;
            margin-bottom: 25px;
            @media #{$lg,$xs}{
                & br{
                    display: none;
                }
            }
        }
    }
    &-mv{
        &-item{
            margin-bottom: 35px;
        }
        &-title{
            font-size: 17px;
            font-weight: 700;
            line-height: 1;
            color: var(--it-common-black);
            text-transform: capitalize;
        }
        & p{
            font-size: 17px;
            font-weight: 400;
            line-height: 32px;
            color:#4D5756;
        }
    }
}
.it-about-4{
    &-thumb-wrap{
        margin-right: 60px;
        @media #{$md,$xs}{
            margin: 0;
            margin-bottom: 60px;
        }
    }
    &-thumb{
        &-double{
            margin-right: 30px;
            & img{
                border-radius: 1000px;
            }
        }
        &-single{
            & img{
                border-radius: 1000px;
            }
        }
    }
    &-list-icon{
        & span{
            margin-top: 7px;
            height: 60px;
            width: 60px;
            line-height: 67px;
            border-radius: 10px;
            display: inline-block;
            text-align: center;
            color: var(--it-common-white);
            background-color: var(--it-common-purple-3);
            margin-right: 30px;
            font-size: 27px;
        }
    }
    &-style{
        & .it-about-3-mv-item {
            @media #{$lg}{
                margin-bottom: 10px;
            }
        }
    }
}
.it-about-5 {
    &-thumb {        
        & img {
            max-width: inherit;
            @media #{$xl,$lg,$md,$xs}{
                max-width: 100%;
            }
        }
        &-box {
            margin-left: -23px;
            @media #{$md,$xs}{
                margin-left: 0;
                text-align: center;
                margin-bottom: 50px;
            }
        }
    }
    &-shape {
        &-1 {
            position: absolute;
            top: -15%;
            left: -33%;
        }
        &-2 {
            position: absolute;
            right:44%;
            top: 0px;
            animation: itleftright 1s infinite alternate;
        }
        &-3 {
            position: absolute;
            right: 120px;
            top: 0px;
            animation: itswing 1s forwards infinite alternate;
            transform-origin: top left;
            @media #{$xl}{
                right: 75px;
                top: -30px; 
            }
        }
        &-4 {
            position: absolute;
            right: 136px;
            bottom: 115px;
            animation: rotate2 12s linear infinite;
        }
    }
    &-list {
        & ul {
            padding: 11px 25px;
            display: inline-block;
            background-color: #F3F5F5;
            border-radius: 5px;   
            & li {
                list-style-type: none;
                font-size: 17px;
                font-weight: 500;
                line-height: 32px; 
                text-transform: capitalize;
                padding-left: 25px;
                position: relative;
                color: #191659;  
                display: inline-block;  
                &:not(:last-child){
                    padding-right: 45px;
                }         
                & i {
                    color: var(--it-theme-4);
                    position: absolute;
                    top: 6px;
                    left: 0;
                }
            }        
        }
    }
}
.inner-about-style{
    & .it-section-subtitle {
        background-color:transparent;
        padding-left: 0;
        & img {
            margin-right: 3px;
        }
    }
    & .it-section-title-3 {
        & span {
            color: var(--it-theme-1);
        }
    }
    & .it-about-4-list-icon {
        & span {
            background-color: var(--it-theme-1);
        }
    }
}

.ed-about{
    &-style-2 {
        & .it-about-right-box {
            margin-left: -28px;
            @media #{$md,$xs}{
                margin-left: 0;
            }
        }
    }
    &-thumb {
        &-box {
            margin-right: 75px;
            padding-top: 300px;
            @media #{$md}{
                margin-bottom: 70px;
            }
            @media #{$xs}{
                margin-right: 0;
                padding-top: 0;
                margin-bottom: 70px;
            }
        }
        &-1 {
            text-align: end;
           
            & img {
                border-radius: 10px;
                @media #{$xs}{
                    width: 100%;
                }
            }
        }
        &-2 {
            position: absolute;
            top: 0;
            left: -10%;
            & img {
                border-radius: 10px;
                @media #{$xs}{
                    width: 100%;
                }
            }
            @media #{$xxl}{
                left: 0;
            }
            @media #{$xl}{
                top: 2%;
                transform: scale(.7);
            }
            @media #{$lg}{
                transform: scale(.8);
            }
            @media #{$md}{
                left: 0;
            }
            @media #{$xs}{
                position: static;
                margin: 20px 0;
            }
        }
        &-3 {
            position: absolute;
            left: 46%;
            top: 8.3%;
            @media #{$xxl}{
                left: 55%;
            }
            @media #{$md}{
                left: 55%;
            }
            @media #{$xs}{
                position: static;
            }
            & img {
                border-radius: 10px;
                @media #{$xs}{
                    width: 100%;
                }
            }
        }
    }
    &-shape-1  {
        position: absolute;
        right: 4%;
        top: 1%;
        z-index: -1;     
        animation: zoom2 4s infinite; 
    }
    &-experience {
        position: absolute;
        top: 30%;
        left: 27%;
        height: 244px;
        width: 244px;
        border-radius: 50%;
        text-align: center;
        padding-top: 60px;
        background-color: var(--it-common-yellow);
        border: 5px solid var(--it-common-white);
        @media #{$xl}{
            transform: scale(.8);
            left: 20%;
            top: 25%;
        }
        @media #{$lg}{
            transform: scale(.7);
            left: 20%;
            top: 25%;
        }
        & span {
            & b {
                font-size: 50px;
            }
            font-weight: 500;
            font-size: 25px;
        }
    }
}
.ed-about-2 {
    &-left {
        margin-right: 80px;
        @media #{$xxl,$xl}{
            margin-right: 20px;
        }
        @media #{$lg}{
            margin-right: 0px;
        }
        @media #{$md}{
            margin-right: 0px;
            margin-bottom: 80px;
        }
        @media #{$xs}{
            margin-right: 0px;
            margin-bottom: 80px;
        }
        &-box{
            @media #{$xs}{
                flex-wrap: wrap;
            }
        }
    }
    &-thumb{
        &-one {
            flex: 0 0 auto;
            @media #{$xs}{
                padding-bottom: 20px;
                margin-right: 0;
                width: 100%;
            }
            & img {
                flex: 0 0 auto;
                width: 100%;
            }
        }
        &-two {
            & img {
                width: 100%;
                &.inner-top-img{
                    width: inherit;
                }
            }
        }
        &-shape {
            &-1 {
                position: absolute;
                bottom: 5%;
                left: 4%;
            }
            &-2 {
                position: absolute;
                top: -2%;
                left: -11%;
                animation: itswing 0.9s forwards infinite alternate;
                transform-origin: bottom right;
                @media #{$xxl}{
                    top: 10%;
                    left: -10%;
                }
            }
        }
    }
}
.ed-about-3{
    &-thumb {
        & img {
            @media #{$xs}{
                width: 100%;
            }
        }
    }
    &-thumb-wrap{
        padding-bottom: 160px;
        @media #{$md}{
           margin-bottom: 50px;
        }
        @media #{$xs}{
           margin-bottom: 50px;
           padding-bottom: 0;
        }
    }
    &-thumb-sm {
        position: absolute;
        bottom: 0;
        right: 12%;
        @media #{$xs}{
            margin-top: 20px;
            position: static;
        }
        & img {
            @media #{$xs}{
                width: 100%;
            }
        }
    }
    &-shape {
       &-1 {
            position: absolute;
            bottom: 5%;
            left: 10%;
       }
       &-2 {
            position: absolute;
            top: 7%;
            left: 5%;
            animation: itrotate 3s ease-in-out 3s infinite alternate forwards;
       }
    }
}
.ed-about-4 {
    &-wrap {

        & .ed-hero-thumb-sm {
            left: 0;
        }
        & .ed-hero-thumb-wrap {
            margin-right: 75px;
            @media #{$md}{   
                margin:0 55px; 
                margin-bottom: 90px;
            }
            @media #{$xs}{    
                margin-bottom: 90px;
                margin-right: 0;
                margin-left: 0;
            }
        }
        & .ed-hero-thumb-student {
            left: 0;

            & span {
                & i {
                    color: var(--it-theme-6);
                }
            }
        }
        & .it-about-5-right {
            margin-left: -35px;
            @media #{$md,$xs}{    
                margin-left: 0;
            }
        }
        & .ed-hero-thumb-shape-2 {
            top: 0%;
            right: 2%;
        }
        & .it-about-5-shape-4 {
            right: auto;
            bottom: auto;
            top: 4%;
            left: 3%;
            animation: none;
            animation: ittranslateY2 4s infinite alternate;
        }
        & .it-about-5-shape-5{
            position: absolute;
            bottom: 15%;
            right: 0;
            animation: itswing-2 3s forwards infinite alternate;
            transform-origin: bottom right;
        }
        & .it-about-5-list {
            & ul {
                display: inline-block;
                padding: 0;
                background-color: transparent;
                & li {
                    list-style-type: none;
                    font-size: 17px;
                    font-weight: 500;
                    text-transform: capitalize;
                    padding: 11px 25px;
                    padding-left: 55px;
                    position: relative;
                    color: #191659;  
                    display: inline-block; 
                    background-color: #F3F5F5; 
                    border-radius: 5px;   
                    margin-bottom: 20px;  
                    @media #{$xl}{                 
                        margin-left: 0;
                        font-size: 15px;
                    }  
                    @media #{$lg}{
                        font-size: 12px;
                    }    
                    @media #{$md,$xs}{
                        font-size: 15px;
                    }    
                    & i {
                        color: var(--it-theme-5);
                        position: absolute;
                        top: 18px;
                        left: 25px;
                    }
                }        
            }
            &.list-style-2 {
                margin-left: -55px;
                @media #{$md,$xs}{    
                    margin-left: 0;
                }
            }
        }
    }
}
.ed-about-5{
    &-content {
        & .it-about-5-list {
            & ul {
                background-color: transparent;
                padding-left: 0;
                padding-top: 0;
                padding-top: 0;
                padding-bottom: 0;
                & li {
                    padding-top: 0;
                    padding-bottom: 0;
                    font-size: 20px;
                    width: 215px;
                    & i {
                        color: var(--it-theme-2);
                    }
                }
            }
        }
    }
    &-thumb-2 {
        padding-top: 95px;
    }
    &-shape-1 {
        position: absolute;
        right: -12%;
        top: -12%;
        animation: itswing 0.9s forwards infinite alternate;
        transform-origin: bottom right;
        z-index: -1;
    }
    &-shape-2 {
        position: absolute;
        bottom: 13%;
        left: 37%;
        animation: itrotate 3s ease-in-out 3s infinite alternate forwards;
    }
}
.it-about-5 {
    &-right {
        @media #{$md,$xs}{
            margin-bottom: 60px;
        } 
    }
    &-text {
        & p {
            @media #{$xl,$lg,$md,$xs}{
                font-size: 15px;
                & br {
                    display: none;
                }
            } 
        }
    }
}